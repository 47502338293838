<template>
  <div class="main-forgetPwd">
      <div class="views">
          <div class="title">忘记密码</div>
          <div class="zh-title">手机号</div>
          <input class="input-title" type="text" v-model="phone" placeholder="请输入手机号">
          <div class="mm-title">验证码</div>
          <div class="code-view">
            <input class="input-title" type="text" v-model="code" placeholder="请输入验证码">
            <div class="codeBtn" @click.stop="sendMethod()" :style="{color:isSkin}">{{codeBtn}}</div>
          </div>
          <div class="login" @click.stop="validationMethod()" :style="{backgroundColor:isSkin}">验证</div>
      </div>
  </div>
</template>

<script>
import {Message} from 'element-ui'
export default {
  data() {
    return {
      phone:'',
      code:'',
      codeBtn: '获取验证码',
      isClick: true,
    }
  },
  created(){
    this.$store.commit('changClick',1)
    var skin = sessionStorage.getItem('Skin')
    var zskin = sessionStorage.getItem('ZSkin')
    if (skin) {
        this.$store.commit('changSkin',skin)
    }
    if (zskin) {
        this.$store.commit('changZSkin',zskin)
    }
  },
  computed:{
    isSkin(){
		return this.$store.state.isSkin
    }
  },
  methods:{
    /**发送验证码**/
    sendMethod(){
      var that = this;
			let count = 60;
			if (that.phone == '') {
        Message({
          showClose: true,
          message: '请输入手机号！',
          type: 'error'
        })
			}else if (that.isClick) {
        that.$api.getSmsCode({
          mobile:that.phone,
        }).then(res=>{
          console.log(res);
          if (res.data.code == 0) {
            Message({
              showClose: true,
              message: '已发送，请注意查收',
              type: 'success'
            })
            that.isClick = false;
            let timer = setInterval(function() {
              count--;
              that.codeBtn = '获取中(' + count + ')';
              if (count == 0) {
                that.isClick = true;
                clearInterval(timer)
                that.codeBtn = '重新获取';
              }
            }, 1000)
					}else{
            that.isClick = true;
          }
        })
      } 
    },
    /**验证**/
    validationMethod(){
      var that = this
      if (that.phone == '') {
        Message({
          showClose: true,
          message: '请输入手机号！',
          type: 'error'
        })
        return
			}
      if (that.code == '') {
        Message({
          showClose: true,
          message: '请输入验证码！',
          type: 'error'
        })
        return
			}
      that.$api.getForgetPwdCheck({
        mobile:that.phone,
        code:that.code
      }).then(res=>{
        if (res.data.code == 0) {
          Message({
            showClose: true,
            message: '手机号验证成功！',
            type: 'success'
          })
          setTimeout(()=>{
            this.$router.push({path:'/resetPwd',query:{phone:that.phone}});
            this.$store.commit('changClick',1)
          },2000)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.main-forgetPwd{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .views{
        width: 427px;
        height: 376px;
        background: #FCFCFC;
        margin-top: 50px;
        margin-bottom: 175px;
        .title{
            font-size: 24px;
            line-height: 34px;
            color: #333333;
            margin-left: 20px;
            margin-top: 30px;
        }
        .zh-title{
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin-left: 20px;
            margin-top: 40px;
        }
        .mm-title{
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin-left: 20px;
            margin-top: 20px;
        }
        .code-view{
          position: relative;
          display: flex;
          justify-items: center;
          align-items: center;
          .codeBtn{
            height: 40px;
            position: absolute;
            right: 30px;
            top: 10px;
            font-size: 14px;
            line-height: 40px;
            cursor: pointer;
          }
        }
        .login{
            width: 387px;
            height: 42px;
            margin-left: 20px;
            margin-top: 40px;
            border-radius: 4px;
            font-size: 16px;
            line-height: 42px;
            text-align: center;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}
.input-title{
    width: 387px;
    height: 40px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none; //去点击蓝色边框
    padding-left: 10px;
    padding-right: 100px;
    font-size: 15px;
    line-height: 40px;
    color: #666666;
    margin-left: 20px;
    margin-top: 10px;
}
</style>